import LocalTime from '@/utility/local_time';
import actions from './actions';

describe('actions', () => {
  describe('toggleSelectedEncoders', () => {
    it('should commit addSelectedEncoder when selectedEncoder contains encoderId', () => {
      const spy = jest.fn();
      const encoderId = '0';
      const parameters = { selectedEncoders: ['0'], encoderId };

      actions.toggleSelectedEncoders({
        commit: spy,
        rootState: {
          encoder: {},
        },
        rootGetters: {
          'system/systemTimezone': -28800,
        },
      }, parameters);

      expect(spy).toBeCalledWith('addSelectedEncoder', {
        channel: encoderId,
        encoders: {},
        timezone: -28800,
      });
    });

    it('should commit removeSelectedEncoder when selectedEncoder does not contain encoderId', () => {
      const spy = jest.fn();
      const encoderId = '0';
      const parameters = { selectedEncoders: ['1'], encoderId };

      actions.toggleSelectedEncoders({
        commit: spy,
        rootState: {
          encoder: {},
        }
      }, parameters);

      expect(spy).toBeCalledWith('removeSelectedEncoder', { channel: encoderId, encoders: {} });
    });
  });
  describe('updateTime', () => {
    it('should call mutation updateTime and query once if target contains 1 item', () => {
      const spy1 = jest.fn().mockResolvedValue({});
      const spy2 = jest.fn().mockResolvedValue({});
      const spy3 = jest.fn().mockResolvedValue({});
      const spy4 = jest.fn().mockResolvedValue({});
      const TARGET = [{
        queryRecordingData: spy1,
        queryAlarmData: spy2,
        queryVCAEventData: spy3,
        queryBookmarkData: spy4
      }];
      const COMMIT = jest.fn();
      const BEGIN = new LocalTime(0).useUniTimezone();
      actions.updateTime({
        commit: COMMIT,
      }, {
        target: TARGET,
        begin: BEGIN,
        query: true,
      });
      expect(COMMIT.mock.calls[0][0]).toEqual('updateTime');
      expect(spy1).toBeCalledTimes(1);
      expect(spy2).toBeCalledTimes(1);
      expect(spy3).toBeCalledTimes(1);
      expect(spy4).toBeCalledTimes(1);
    });
    it('should call mutation updateTime once if target contains 1 item', () => {
      const spy1 = jest.fn().mockResolvedValue({});
      const spy2 = jest.fn().mockResolvedValue({});
      const spy3 = jest.fn().mockResolvedValue({});
      const spy4 = jest.fn().mockResolvedValue({});
      const TARGET = [{
        queryRecordingData: spy1,
        queryAlarmData: spy2,
        queryVCAEventData: spy3,
        queryBookmarkData: spy4
      }];
      const COMMIT = jest.fn();
      const BEGIN = new LocalTime(0).useUniTimezone();
      actions.updateTime({
        commit: COMMIT,
      }, {
        target: TARGET,
        begin: BEGIN,
        query: false,
      });
      expect(COMMIT.mock.calls[0][0]).toEqual('updateTime');
      expect(spy1).toBeCalledTimes(0);
      expect(spy2).toBeCalledTimes(0);
      expect(spy3).toBeCalledTimes(0);
      expect(spy4).toBeCalledTimes(0);
    });
  });
  describe('applyUrlQueryToViewcell', () => {
    it('should call dispatch with toggleSelectedEncoders and updateTime', () => {
      const dispatch = jest.fn();
      const getters = {
        filteredView: () => ({})
      };
      const params = {
        channel: 0,
        timestamp: 1,
        tzoffs: 2
      };
      const begin = new LocalTime(
        params.timestamp,
      ).useUniTimezone();
      actions.applyUrlQueryToViewcell({ dispatch, getters }, params);

      expect(dispatch.mock.calls[0]).toEqual(['toggleSelectedEncoders', { encoderId: 0, selectedEncoders: [0] }]);
      expect(dispatch.mock.calls[1]).toEqual(['updateTime', { begin, target: {}, tzoffs: 2 }]);
    });
  });
});
